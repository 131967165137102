import { GoogleAnalytics, event, usePageViews } from "nextjs-google-analytics";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { EB_Garamond, Nunito_Sans, Lato } from "next/font/google";
import { ReCaptchaProvider } from "next-recaptcha-v3";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const lato = Lato({
  subsets: ["latin"],
  display: "swap",
  weight: ["100", "300", "400", "700", "900"],
  variable: "--font-lato",
});

const garamond = EB_Garamond({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-garamond",
});

const nunito_sans = Nunito_Sans({
  subsets: ["latin"],
  display: "swap",
});

import "../styles/lite-yt-embed.css";
import "../styles/tailwind.css";

import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

import { AppProps } from "next/app";

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: {
  id: string;
  name: string;
  label: string;
  value: number;
}) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  usePageViews();

  return (
    <>
      {/* Certain elements like selects and dialogs from headless-ui mount their popover components further up the DOM tree than main and weren't recieving the font styles  */}
      <style jsx global>{`
        html {
          font-family: ${nunito_sans.style.fontFamily};
        }
      `}</style>
      <main className={`${garamond.variable} ${lato.variable}`}>
        <GoogleAnalytics />
        <SpeedInsights />
        <Analytics />
        <ReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        >
          <Component {...pageProps} />
        </ReCaptchaProvider>
      </main>
    </>
  );
}

export default MyApp;
